<template>
  <div>
    <v-row>
      <v-col>
        <div style="max-width:180px">
          <h4>{{ $t('vision.choisir_un_sondages') }}</h4>
          <v-select
            v-model="selectionSondage"
            no-data-text="Aucun sondage de configuré"
            :items="sondages"
            item-text="nom"
            return-object
            @change="onSelectionSondage"
          />
          <h4>
            {{ $t('vision.choisir_une_question') }}
          </h4>
          <v-select
            v-if="selectionSondage"
            v-model="selectionQuestion"
            :items="selectionSondage.listeQuestions"
            return-object
            @change="onToggleSondage"
          >
            <template v-slot:selection="data">
              <span class="sansMajuscules">
                {{ data.item.texte | stripHtml }}
              </span>
            </template>
            <template v-slot:item="data">
              {{ data.item.texte | maxCar(70) | stripHtml }}
            </template>
          </v-select>
        </div>
      </v-col>
      <v-col>
        <div
          style="max-width:220px"
          class="mx-4 px-4"
        >
          <h4>{{ $t('vision.reponses') }}</h4>
          <div
            v-for="info in infoChoixRéponse"
            :key="info.id"
          >
            <div class="text-caption">
              <span class="">{{ info.texte | stripHtml | maxCar(120) }} :</span> <strong> {{ info.nombreRéponses }} {{ pourcentageRéponse(info, infoChoixRéponse) + "%" }}</strong>
            </div>
          </div>
        </div>
      </v-col>
      <v-col>
        <div style="width:180px">
          <h4 class="ml-10 mb-2">
            {{ $t('vision.graph') }}
          </h4>
          <chartist
            class="ct-chart ct-square"
            :data="chartChoix.data"
            :options="chartChoix.options"
            type="Bar"
            style="max-height: 150px;"
          />
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-btn
        fab
        small
        class="mr-3 mb-3"
        :disabled="!selectionQuestion"
        @click="onQuestionPrécédente()"
      >
        <v-icon>mdi-arrow-left-bold</v-icon>
      </v-btn>
      <v-btn
        fab
        small
        class="mr-3 mb-3"
        :disabled="!selectionQuestion"
        @click="onQuestionSuivante"
      >
        <v-icon>mdi-arrow-right-bold</v-icon>
      </v-btn>
      <v-btn-toggle
        v-model="etatAffichageQuestion"
        dense
      >
        <v-btn
          text
          class="primary"
          :disabled="!selectionQuestion"
          :small="$vuetify.breakpoint.mobile"
          @click="onAfficherQuestion()"
        >
          {{ $t('vision.publier_question') }}
        </v-btn>
        <v-btn
          text
          :disabled="!selectionQuestion"
          :small="$vuetify.breakpoint.mobile"
          @click="onMasquerQuestion()"
        >
          {{ $t('vision.masquer_question') }}
        </v-btn>
        <v-btn
          text
          :disabled="!selectionQuestion"
          :small="$vuetify.breakpoint.mobile"
          @click="onAfficherResultat()"
        >
          {{ $t('vision.partager_resultats') }}
        </v-btn>
      </v-btn-toggle>
    </v-row>
  </div>
</template>
<script>

  import restApiService from '@/services/restApiService.js'
  import i18n from '@/i18n.js'
  import ToucanCom from '@/services/toucanCom'
  import Salon from '@/services/salon'
  import { integer } from 'vee-validate/dist/rules'

  export default {
    name: 'BoiteGestionSondage',
    filters: {
      stripHtml: function (value) {
        if (value === undefined) {
          return ''
        }
        return value.replace(/<[^>]*>?/gm, '')
          .replace('&nbsp;', '')
      },
      maxCar: function (value, m) {
        if (value.length > m) {
          return value.substring(0, m) + '...'
        }
        return value
      },

    },
    props: {
      idConference: integer,
    },
    data: () => ({
      sondages: [],
      etatAffichageQuestion: null,
      selectionSondage: null,
      selectionQuestion: null,
      infoChoixRéponse: [],
      estResponsable: false,
      salon: null,
      chartChoix: {
        data: {
          labels: [],
          series: [
            [],
          ],
        },
        options: {
          onlyInteger: true,
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
    }),
    computed: {
      chaineConference () {
        const chaine = 'ORKA-C-' + this.idConference
        return chaine
      },
    },
    mounted () {
      this.salon = Salon.initConference(this.idConference)
      console.log('salon', this.salon, this.idConference)
      this.salon.entrer('VisionOutil')
      ToucanCom.getInstance().enregistrerActionNotification('statsQuestion', this.onStatsQuestion)
      restApiService.get('/api/sondages/compteClient/' + this.$store.state.compteClient.id).then((res) => {
        this.sondages = res.data
        if (this.sondages.length > 0) {
          this.selectionSondage = this.sondages[0]
          if (this.selectionSondage.listeQuestions.length > 0) {
            this.selectionQuestion = this.selectionSondage.listeQuestions[0]
          }
          this.onToggleSondage()
        }
      })
    },
    beforeDestroy() {
      this.salon.sortir()
    },
    methods: {
      pourcentageRéponse(info, infoChoixRéponse) {
        // caculer le total de nombreRéponses dans infoChoixRéponse
        var total = infoChoixRéponse.reduce((acc, cur) => {
          return acc + cur.nombreRéponses
        }, 0)
        if (total === 0) {
          return 0
        }
        return Math.round(info.nombreRéponses / total * 100)
      },
      onAfficherQuestion () {
        restApiService.get(`/api/salons/${this.chaineConference}/afficherQuestion/${this.selectionQuestion.id}`).then((res) => {
          this.$dialog.notify.success(i18n.t('vision.question_publiee'))
        })
      },
      onMasquerQuestion () {
        restApiService.get(`/api/salons/${this.chaineConference}/masquerQuestion/${this.selectionQuestion.id}`).then((res) => {
          this.etatAffichageQuestion = null
          this.$dialog.notify.success(i18n.t('vision.question_masquee'))
        })
      },
      onSelectionSondage () {
        this.selectionQuestion = this.selectionSondage.listeQuestions[0]
        this.onToggleSondage()
      },
      onAfficherResultat () {
        restApiService.get(`/api/salons/${this.chaineConference}/afficherQuestionResultat/${this.selectionQuestion.id}`).then((res) => {
          this.$dialog.notify.success(i18n.t('vision.resultats_partages'))
        })
      },
      majStats (info) {
        this.chartChoix.data.labels = info.listeInfoChoix.map(i => {
          return this.selectionQuestion.listeChoix.find(
            c => c.id === i.idChoix)
            .texte.replace(/<[^>]*>?/gm, '').replace('&nbsp;', '').substring(0, 20)
        })
        this.chartChoix.data.series = [info.listeInfoChoix.map(i => i.nombreRéponses)]
        this.infoChoixRéponse = info.listeInfoChoix.map(s => ({
          id: s.id,
          texte: this.selectionQuestion.listeChoix.find(c => c.id === s.idChoix).texte,
          nombreRéponses: s.nombreRéponses,
        }))
      },
      onStatsQuestion (s) {
        this.majStats(s.info)
      },
      onToggleSondage () {
        restApiService.get(`/api/sondage-questions/${this.selectionQuestion.id}/conference/${this.idConference}`).then((r) => {
          this.majStats(r.data)
        })
      },
      onQuestionPrécédente () {
        const idx = this.selectionSondage.listeQuestions.indexOf(this.selectionQuestion)
        if (idx === 0) {
          return
        }
        this.selectionQuestion = this.selectionSondage.listeQuestions[idx - 1]
        this.etatAffichageQuestion = null
        this.onToggleSondage()
      },
      onQuestionSuivante () {
        const idx = this.selectionSondage.listeQuestions.indexOf(this.selectionQuestion)
        if (idx >= this.selectionSondage.listeQuestions.length - 1) {
          return
        }
        this.selectionQuestion = this.selectionSondage.listeQuestions[idx + 1]
        this.etatAffichageQuestion = null
        this.onToggleSondage()
      },

    },
  }
</script>
<style lang="scss">

</style>
