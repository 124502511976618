<template>
  <v-container
    id="gestionConference"
    fluid
    tag="section"
    data-cy="pageGestionConference"
  >
    <h1 class="font-weight-light mb-2 text-h5">
      {{ conference.nom }}
    </h1>
    <base-material-card
      icon="mdi-chart-box-outline"
      inline
      class="px-6 py-10"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          {{ $t('sondages_page.sondages') }}
        </div>
      </template>
      <boite-gestion-sondage
        class="mt-10"
        :id-conference="idConference"
      />
    </base-material-card>
    <gestion-question
      :chaine="chaineConference"
      :user-info="$store.state.userInfo"
    />
    <gestion-prise-parole
      v-if="conference.priseParole"
      :conference="conference"
    />
  </v-container>
</template>

<script>

  import GestionQuestion from '../../agora/GestionQuestion'
  import restApiService from '@/services/restApiService.js'
  import BoiteGestionSondage from '../composantes/BoiteGestionSondage.vue'
  import { mapState, mapMutations } from 'vuex'
  import GestionPriseParole from '../composantes/GestionPriseParole.vue'
  import toucanService from '@/services/toucanService.js'

  export default {
    components: {
      GestionQuestion,
      BoiteGestionSondage,
      GestionPriseParole,
    },
    data: () => ({
      conference: {},
      idConference: null,
    }),
    computed: {
      ...mapState(['drawer']),
      chaineConference () {
        const chaine = 'ORKA-C-' + this.$route.params.idConference
        return chaine
      },
    },
    created () {
      this.idConference = this.$route.params.idConference

      restApiService
        .get(`/api/webUser/infoConference/${this.idConference}`)
        .then(result => {
          if (result.data.estConferencier || result.data.estResponsable) {
            this.setDrawer(false)
            this.chargerConference()
          } else {
            toucanService.allerPresentationsSinonSalleAccueil()
          }
        })
        .catch(error => alert(error))
    },
    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
      chargerConference() {
        restApiService
          .get('/api/webUser/infoConference/' + this.idConference)
          .then(result => {
            this.conference = result.data.conference
          })
          .catch(error => alert(error))
      },
    },
  }
</script>
